import { Patch } from './Patch';
import { Hook } from './UndoManagerHook';

type StackHooks = {
  onStatusChanged?: Hook<'onRedoStatusChanged' | 'onUndoStatusChanged'>;
};

type StackOptions = {
  limit?: number;
  onStatusChanged?: Hook<'onRedoStatusChanged' | 'onUndoStatusChanged'>;
  debug?: boolean;
};

export class Stack {
  private list: Patch[] = [];
  private limit: number;
  private status: Realtime.Core.UndoManager.StackStatus = 'EMPTY';
  private hooks: StackHooks = {};

  private debug: boolean = false;

  constructor(options?: StackOptions) {
    this.limit = options?.limit || 100;
    if (options?.onStatusChanged) {
      this.hooks.onStatusChanged = options.onStatusChanged;
    }
    this.debug = !!options?.debug;
  }

  private evaluateStatus() {
    const newStatus: Realtime.Core.UndoManager.StackStatus = this.isEmpty ? 'EMPTY' : 'NOT_EMPTY';
    if (newStatus !== this.status) {
      this.status = newStatus;
      if (this.hooks.onStatusChanged) {
        this.hooks.onStatusChanged.trigger(this.status);
      }
    }
  }

  get isEmpty() {
    return this.list.length <= 0;
  }

  get length() {
    return this.list.length;
  }

  get(index: number) {
    return this.list[index];
  }

  getLast() {
    const lastIndex = this.list.length - 1;
    if (lastIndex < 0) {
      throw new Error('Stack empty');
    }
    return this.list[lastIndex];
  }

  pop() {
    const element = this.list.pop();
    this.evaluateStatus();
    return element;
  }

  setLast(item: Patch) {
    this.list.push(item);
    const overflow = this.list.length - this.limit;
    if (overflow > 0) {
      this.list.splice(0, overflow);
    }
    this.evaluateStatus();
  }

  transformStack(doc: Realtime.Core.RealtimeObject, ops: Realtime.Core.RealtimeOps) {
    this.debugMessage('Transforming stack...', doc, ops);

    let workingOps: Realtime.Core.RealtimeOps = ops;
    const newList: Patch[] = [];
    // eslint-disable-next-line no-var
    for (var i = this.list.length - 1; i >= 0; --i) {
      const patch = this.list[i];
      try {
        this.debugPatch('Before Transform', patch);
        workingOps = patch.transformPatch(doc, workingOps);
        this.debugPatch('After Transform', patch);
        if (!patch.isEmpty) {
          newList.push(patch);
        }
      } catch (error) {
        logger.warn('TransformStack error', patch);
      }
    }
    this.list = newList.reverse();
    this.evaluateStatus();
  }

  private debugMessage(message: string, ...args: any[]) {
    if (this.debug) {
      logger.debug(message, ...args);
    }
  }

  private debugPatch(message: string, patch: Patch) {
    if (this.debug) {
      const args: [string, ...any] = [message, `Patch Ops: ${patch.id}`];

      for (let i = 0; i < patch.components.length; i++) {
        args.push(
          patch.components[i].doc.modelType,
          ...JSON.parse(JSON.stringify(patch.components[i].op)),
        );
      }

      logger.debug(...args);
    }
  }

  clear() {
    this.list = [];
    this.evaluateStatus();
  }
}
