import { DoDocObject } from './AbstractDocument';

export class DoPDFDocument extends DoDocObject {
  protected getObjectType(): Realtime.Core.Document.DocumentType {
    return 'dopdf';
  }

  get versions() {
    return this.data?.lifecycle?.versions || [];
  }
}
